














import { Component, Vue, Watch } from 'vue-property-decorator';
// import LayoutModule from '@/store/modules/LayoutModule';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';

import explorerrecords from '@/pages/explorer/components/ExplorerRecords.vue';
import explorercontenttitlebar from '@/pages/explorer/components/ExplorerContentTitlebar.vue';
import { ISelectFolder } from '@/store/types/ISelectFolder';
import explorerlayout from '@/components/layouts/ExplorerLayout.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

@Component({
  components: {
    explorerrecords,
    explorercontenttitlebar,
    explorerlayout,
  },
})
export default class ExplorerPage extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    // when the component first loads
    // whatever I change here I need to change in beforeRouteUpdate
    ExplorerUiStateModule.selectFolder({
      folderName: to.params.folder,
      selectedId: to.params.selecteditemid,
    } as ISelectFolder);
    next();
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    // when the url changes
    // whatever I change here I need to change in beforeRouteEnter
    ExplorerUiStateModule.selectFolder({
      folderName: to.params.folder,
      selectedId: to.params.selecteditemid,
    } as ISelectFolder);
    next();
  }
}
